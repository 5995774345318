



















import Vue from "vue";

export default Vue.extend({
  name: "ClubMain2D",
  data: () => {
    return {
      loaded: false,
    };
  },
  mounted() {
    //this.getData();
    this.loaded = true;
  },
});
